import React, { useState, useEffect } from "react";
import classes from "./users.module.scss";
import { getAllUsers, deleteUser } from "../../../api/misc";
import DataTable from "../../../components/shared/customTable";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
} from "@mui/material";
import Button from "../../../components/shared/customBtn";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteModal from "../../../components/shared/deleteModal";

const Users = () => {
  const [rows, setRows] = useState([]); // Store API data
  const [loading, setLoading] = useState(false); // Loading state
  const [loadingDelete, setLoadingDelete] = useState(false); // Loading state
  const [deleteId, setDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false); // Loading state
  const [totalRows, setTotalRows] = useState(0); // Total number of rows from API
  const [count, setCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 20,
  }); // Pagination state
  const carrerOptions = [
    { value: "All" },
    { value: "Consultant Level 1" },
    { value: "Master" },
    { value: "Regional Admin" },
    { value: "Super Admin" },
    { value: "Vocational School" },
  ];
  const statusOptions = [{ value: "All" }, { value: "Yes" }, { value: "No" }];
  const [carrerValue, setCarrerValue] = useState(carrerOptions[0]?.value);
  const [statusValue, setStatusValue] = useState(statusOptions[0]?.value);

  const handleChangeCarrer = (event) => {
    setCarrerValue(event.target.value);
  };
  const handleChangeStatus = (event) => {
    setStatusValue(event.target.value);
  };

  const handleEdit = (row) => {
    console.log("Edit row:", row);
    // Add your edit logic here
  };

  const handleConfirmDelete = async () => {
    console.log("delete success", deleteId);

    setLoadingDelete(true);
    // Add your delete logic here
    const tokenAD = JSON.parse(localStorage.getItem("authToken"));
    const lang = localStorage.getItem("lang");
    // try {
    //   // Replace with your API call, here is a mock example
    //   const res = await deleteUser(id, {
    //     headers: {
    //       Authorization: `Bearer ${tokenAD}`,
    //       lang: lang,
    //     },
    //   });

    //   if (res?.status == 200) {
    //       setOpenDelete(false);
    //     toast.success(res?.data?.message);
    //     setCount(count + 1);
    //   }
    // } catch (error) {
    //   const showErr =
    //     error?.response?.data?.message == "Account Blocked"
    //       ? error?.response?.data?.errors[0]
    //       : error?.response?.data?.message
    //       ? error?.response?.data?.message
    //       : error?.message;
    //   toast.error(showErr);
    // }
    // setLoadingDelete(false);
  };
  const handleDelete = async (id) => {
    setOpenDelete(true);
    setDeleteId(id);
  };
  const handleClose = () => {
    setOpenDelete(false);
    setConfirmDelete(false);
    setDeleteId(null);
    setLoadingDelete(false);
  };
  const handleAddNewUSer = () => {
    console.log("add new user");
    // Add your delete logic here
  };
  const handleExport = () => {
    console.log("handle export");
    // Add your delete logic here
  };
  const columns = [
    // { field: "id", headerName: "ID", width: 70, resizable: false, flex: 1 },
    {
      field: "full_name",
      headerName: "User",
      flex: 2,
      resizable: false,
    },
    { field: "city_name", headerName: "City", resizable: false, flex: 1 },
    { field: "status", headerName: "Status", resizable: false, flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <span
            className={classes.iconStyleEdit}
            onClick={() => handleEdit(params.row)}
          >
            <EditIcon />
          </span>

          <span
            className={classes.iconStyleDel}
            onClick={() => handleDelete(params.row.id)}
          >
            <DeleteIcon />
          </span>
        </>
      ),
    },
  ];
  const datas = [
    { id: 131, full_name: "arik", city_code: "265", status: "active" },
    { id: 13123, full_name: "arik", city_code: "265", status: "active" },
    { id: 113, full_name: "arik", city_code: "265", status: "active" },
    { id: 1131, full_name: "arik", city_code: "265", status: "active" },
    { id: 187, full_name: "arik", city_code: "265", status: "active" },
    { id: 1345, full_name: "arik", city_code: "265", status: "active" },
    { id: 183, full_name: "arik", city_code: "265", status: "active" },
    { id: 1786, full_name: "arik", city_code: "265", status: "active" },
    { id: 17648, full_name: "arik", city_code: "265", status: "active" },
    { id: 138, full_name: "arik", city_code: "265", status: "active" },
    { id: 159886, full_name: "arik", city_code: "265", status: "active" },
    { id: 134525, full_name: "arik", city_code: "265", status: "active" },
    { id: 1354576, full_name: "arik", city_code: "265", status: "active" },
    { id: 1754, full_name: "arik", city_code: "265", status: "active" },
    { id: 125634, full_name: "arik", city_code: "265", status: "active" },
    { id: 13654, full_name: "arik", city_code: "265", status: "active" },
    { id: 13345, full_name: "arik", city_code: "265", status: "active" },
    { id: 136367, full_name: "arik", city_code: "265", status: "active" },
    { id: 13457, full_name: "arik", city_code: "265", status: "active" },
    { id: 135678, full_name: "arik", city_code: "265", status: "active" },
    { id: 1354534, full_name: "arik", city_code: "265", status: "active" },
    { id: 1334567, full_name: "arik", city_code: "265", status: "active" },
    { id: 13787, full_name: "arik", city_code: "265", status: "active" },
    { id: 13435, full_name: "arik", city_code: "265", status: "active" },
    { id: 13568, full_name: "arik", city_code: "265", status: "active" },
    { id: 14563, full_name: "arik", city_code: "265", status: "active" },
    { id: 12353, full_name: "arik", city_code: "265", status: "active" },
    { id: 14647, full_name: "arik", city_code: "265", status: "active" },
    { id: 17588, full_name: "arik", city_code: "265", status: "active" },
    { id: 197809780, full_name: "arik", city_code: "265", status: "active" },
    { id: 15764, full_name: "arik", city_code: "265", status: "active" },
    { id: 133675, full_name: "arik", city_code: "265", status: "active" },
    { id: 156656, full_name: "arik", city_code: "265", status: "active" },
    { id: 16575, full_name: "arik", city_code: "265", status: "active" },
    { id: 14567, full_name: "arik", city_code: "265", status: "active" },
    { id: 364, full_name: "arik", city_code: "265", status: "active" },
    { id: 1234553, full_name: "arik", city_code: "265", status: "active" },
    { id: 1343244, full_name: "arik", city_code: "265", status: "active" },
    { id: 13456, full_name: "arik", city_code: "265", status: "active" },
    { id: 16757, full_name: "arik", city_code: "265", status: "active" },
    { id: 16788, full_name: "arik", city_code: "265", status: "active" },
    { id: 14567, full_name: "arik", city_code: "265", status: "active" },
    { id: 13456557, full_name: "arik", city_code: "265", status: "active" },
    { id: 134546, full_name: "arik", city_code: "265", status: "active" },
    { id: 134547, full_name: "arik", city_code: "265", status: "active" },
    { id: 1786456, full_name: "arik", city_code: "265", status: "active" },
    { id: 12546, full_name: "arik", city_code: "265", status: "active" },
    { id: 1678453, full_name: "arik", city_code: "265", status: "active" },
    { id: 13456, full_name: "arik", city_code: "265", status: "active" },
    { id: 1365446, full_name: "arik", city_code: "265", status: "active" },
    { id: 1356, full_name: "arik", city_code: "265", status: "active" },
    { id: 145766, full_name: "arik", city_code: "265", status: "active" },
    { id: 156476, full_name: "arik", city_code: "265", status: "active" },
    { id: 13566, full_name: "arik", city_code: "265", status: "active" },
    { id: 146563, full_name: "arik", city_code: "265", status: "active" },
    { id: 1456656, full_name: "arik", city_code: "265", status: "active" },
    { id: 16546364, full_name: "arik", city_code: "265", status: "active" },
    { id: 1456346, full_name: "arik", city_code: "265", status: "active" },
    { id: 147473, full_name: "arik", city_code: "265", status: "active" },
    { id: 1554535, full_name: "arik", city_code: "265", status: "active" },
    { id: 135344444, full_name: "arik", city_code: "265", status: "active" },
    { id: 1666644, full_name: "arik", city_code: "265", status: "active" },
    { id: 1444777, full_name: "arik", city_code: "265", status: "active" },
    { id: 188383, full_name: "arik", city_code: "265", status: "active" },
    { id: 1363363636, full_name: "arik", city_code: "265", status: "active" },
    { id: 1363346346, full_name: "arik", city_code: "265", status: "active" },
  ];
  // Fetch data from API
  const fetchData = async () => {
    setLoading(true); // Show loading
    const tokenAD = JSON.parse(localStorage.getItem("authToken"));
    const lang = localStorage.getItem("lang");
    try {
      // Replace with your API call, here is a mock example
      const response = await getAllUsers(
        paginationModel.page,
        paginationModel.pageSize,
        {
          headers: {
            Authorization: `Bearer ${tokenAD}`,
            lang: lang,
          },
        }
      );
      console.log("response.data:", response.data.data.data);
      // Assuming the API returns { data: [...], total: number }
      setRows(response.data.data.data);
      setTotalRows(response.data.data.total); // Set total rows for pagination
    } catch (error) {
      const showErr =
        error?.response?.data?.message == "Account Blocked"
          ? error?.response?.data?.errors[0]
          : error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message;
      toast.error(showErr);
    }
    setLoading(false); // Hide loading
  };

  // Trigger data fetch when pagination model changes
  useEffect(() => {
    fetchData();
  }, [paginationModel, count]);

  // Handle page change
  const handlePageChange = (event, value) => {
    setPaginationModel((prev) => ({
      ...prev,
      page: value,
    }));
  };
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handlePageSizeChange = (event) => {
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: event.target.value, // Update pageSize
      page: 1, // Reset to first page when page size changes
    }));
  };

  return (
    <div className={classes.mainUser}>
      <ToastContainer />
      <div className={classes.filter}>
        <h3>Filter By</h3>
        <div className={classes.filterRow}>
          <div className={classes.filterCol}>
            <FormControl variant="outlined">
              <InputLabel>Carrer:</InputLabel>
              <Select
                value={carrerValue}
                onChange={handleChangeCarrer}
                label="Carrer"
                displayEmpty
                sx={{
                  "& .MuiSelect-select": {
                    display: "flex",
                    justifyContent: "space-between",
                  },
                }}
              >
                {/* <span className={classes.title}>Carrer:</span> */}
                {carrerOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <div className={classes.dropdownItem}>
                      <span className={classes.value}>{option.value}</span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.filterCol}>
            <FormControl variant="outlined">
              <InputLabel>Status:</InputLabel>
              <Select
                value={statusValue}
                onChange={handleChangeStatus}
                label="Carrer"
                displayEmpty
                sx={{
                  "& .MuiSelect-select": {
                    display: "flex",
                    justifyContent: "space-between",
                  },
                }}
              >
                {/* <span className={classes.title}>Carrer:</span> */}
                {statusOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <div className={classes.dropdownItem}>
                      <span className={classes.value}>{option.value}</span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.filterCol}>
            <TextField
              label="Name"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Name"
              type="text"
              variant="outlined"
            />
          </div>
        </div>
      </div>
      <div className={classes.addRow}>
        <Button
          customClass={classes.filterBtn}
          black
          custborderRadius
          customWidth="153px"
          onClick={handleAddNewUSer}
        >
          Add New
        </Button>
        <div className={classes.addRowInner}>
          <Button
            customClass={classes.filterBtn}
            outlineGrey
            custborderRadius
            onClick={handleExport}
          >
            Export data
          </Button>
          <div className={classes.paginationControls}>
            <FormControl variant="standard" sx={{ minWidth: 60 }}>
              <InputLabel>Rows</InputLabel>
              <Select
                value={paginationModel.pageSize}
                onChange={handlePageSizeChange}
              >
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <DataTable
        rows={rows}
        columns={columns}
        loading={loading}
        totalRows={totalRows}
        paginationModel={paginationModel}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />
      {deleteId && (
        <DeleteModal
          isOpen={openDelete}
          onClose={handleClose}
          handleConfirmDelete={handleConfirmDelete}
          name="User"
          isLoading={loadingDelete}
        />
      )}
    </div>
  );
};

export default Users;
