import React from 'react';
import "./style.scss";
const Unauthorized = () => {
    return (
        <div className="mainSectionUn">
            <h1>Unauthorized Access</h1>
            <p>You do not have permission to view this page.</p>
        </div>
            
    );
};

export default Unauthorized;
