import React , {useState, useEffect} from "react";
import { Outlet } from "react-router-dom";
import Header from "../header";
import Sidebar from "../sidebar";
import classes from "./adminLayout.module.scss";
import "./adminBuiltin.scss"

const AdminLayout = () => {
  const [isNavbar , setIsNavbar]= useState(false)
  const [closeNavbar2 , setCloseNavbar2]= useState(false)
  const handleArrow = () =>{
    setCloseNavbar2(false)
    setIsNavbar(!isNavbar)
  }
  return (
    <>
      <div className={classes.mainLayoutSection}>
          <div className={`${classes.mainHeader} ${isNavbar && classes.removeMarginHead}`}>  
            <Header handleArrow={handleArrow} />
          </div>
        <div className={classes.mainSidebarContent}>
            <div className={`${classes.mainSidebar} ${isNavbar && classes.openSideBar} ${closeNavbar2 && classes.closeSideBar} ${isNavbar && "hideMenuItem"}`} >
              <Sidebar setCloseNavbar2={setCloseNavbar2} isNavbar={isNavbar} setIsNavbar={setIsNavbar} handleArrow={handleArrow}/>
            </div>
            <div className={`${classes.mainContent} ${isNavbar && classes.removeMargin}`}>
              <Outlet />
            </div>
        </div>
      </div>
    </>
  );
};

export default AdminLayout;
