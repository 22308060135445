import React, { useState } from "react";
import classes from "./settingSidebar.module.scss";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const SettingSiderbar = () => {
  const location = useLocation();
  const lastSegment = location.pathname.split("/").filter(Boolean).pop();
  const [activeLink, setActiveLink] = useState(lastSegment);

  const handleLinkClick = (link) => {
    setActiveLink(link); // Update the active link when a link is clicked
  };

  return (
    <div className={classes.mainSidebar}>
      <ul>
        <li>
          <Link
            to="settings/general-settings"
            className={activeLink === "general-settings" ? classes.active : ""}
            onClick={() => handleLinkClick("general-settings")}
          >
            General settings
          </Link>
        </li>
        <li>
          <Link
            to="settings/appearance"
            className={activeLink === "appearance" ? classes.active : ""}
            onClick={() => handleLinkClick("appearance")}
          >
            Appearance
          </Link>
        </li>
        <li>
          <Link
            to="settings/privacy"
            className={activeLink === "privacy" ? classes.active : ""}
            onClick={() => handleLinkClick("privacy")}
          >
            Privacy & Safety
          </Link>
        </li>
        <li>
          <Link
            to="settings/notifications"
            className={activeLink === "notifications" ? classes.active : ""}
            onClick={() => handleLinkClick("notifications")}
          >
            Notifications
          </Link>
        </li>
        <li>
          <Link
            to="settings/banks"
            className={activeLink === "banks" ? classes.active : ""}
            onClick={() => handleLinkClick("banks")}
          >
            Banks
          </Link>
        </li>
        <li>
          <Link
            to="settings/products"
            className={activeLink === "products" ? classes.active : ""}
            onClick={() => handleLinkClick("products")}
          >
            Products
          </Link>
        </li>
        <li>
          <Link
            to="settings/users"
            className={activeLink === "users" ? classes.active : ""}
            onClick={() => handleLinkClick("users")}
          >
            Users
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default SettingSiderbar;
