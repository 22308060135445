import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminLayout from "./components/adminLayout";
import NoPage from "./pages/nopage";
import ProtectedRoute from "./components/protectedRoute";
import AuthRedirectRoute from "./components/protectedRoute/authRedirectRoute";
import Unauthorized from "./components/unauthorized";
import "./index.css";
// auth links
import Login from "./pages/Auth/Login";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import VerifyAccount from "./pages/Auth/VerifyAccount";

// pages links
import Home from "./pages/home";

// settings Pages
import SettingsLayout from "./pages/settings";
import GeneralSettings from "./pages/settings/generalSettings";
import Appearance from "./pages/settings/appearance";
import Privacy from "./pages/settings/privacy";
import Notifications from "./pages/settings/notifications";
import Banks from "./pages/settings/banks";
import Products from "./pages/settings/products";
import Users from "./pages/settings/users";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        {/* Auth links  */}
        <Route
          path="/sign-in"
          element={
            // <AuthRedirectRoute>
            <Login />
            // </AuthRedirectRoute>
          }
        />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verify-account" element={<VerifyAccount />} />

        <Route path="/unauthorized" element={<Unauthorized />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <AdminLayout />
            </ProtectedRoute>
          }
        >
          <Route path="*" element={<NoPage />} />
          <Route
            index
            element={
              <ProtectedRoute requiredPermissions={["view summary"]}>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/overview/summary"
            element={
              <ProtectedRoute requiredPermissions={["view summary"]}>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/loans"
            element={
              <ProtectedRoute requiredPermissions={["view loans"]}>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/messages"
            element={
              <ProtectedRoute requiredPermissions={["view messages"]}>
                <Home />
              </ProtectedRoute>
            }
          />
          {/* setting start  */}
          <Route
            path="/"
            element={
              <ProtectedRoute requiredPermissions={["settings"]}>
                <SettingsLayout />
              </ProtectedRoute>
            }
          >
            <Route
              path="/settings/general-settings"
              element={
                <ProtectedRoute requiredPermissions={["view general-settings"]}>
                  <GeneralSettings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/appearance"
              element={
                <ProtectedRoute requiredPermissions={["view appearance"]}>
                  <Appearance />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/privacy"
              element={
                <ProtectedRoute
                  requiredPermissions={["view privacy-and-safety"]}
                >
                  <Privacy />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/notifications"
              element={
                <ProtectedRoute requiredPermissions={["view notifications"]}>
                  <Notifications />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/banks"
              element={
                <ProtectedRoute requiredPermissions={["view banks"]}>
                  <Banks />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/products"
              element={
                <ProtectedRoute requiredPermissions={["view products"]}>
                  <Products />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/users"
              element={
                <ProtectedRoute requiredPermissions={["view users"]}>
                  <Users />
                </ProtectedRoute>
              }
            />
          </Route>
          {/* setting end  */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
