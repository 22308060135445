// components/DataTable.js
import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import Loader from "../loader";

const DataTable = ({
  rows,
  columns,
  loading,
  totalRows,
  paginationModel,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalRows / paginationModel.pageSize);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Paper sx={{ minHeight: "400px", width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            rowCount={totalRows}
            paginationMode="server"
            hideFooter // Hide default pagination
            disableColumnMenu={true}
            sortingOrder={["asc", "desc"]}
            sx={{
              border: 0,
              width: "100%",
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold", // Make the header text bold
              },
            }}
          />
          <Pagination
            count={totalPages} // Total number of pages
            page={paginationModel.page} // Current page
            onChange={onPageChange} // Handle page change
            siblingCount={2} // Number of pages to show around the current page
            boundaryCount={1} // Number of pages to show at the start/end
            showFirstButton // Show "First" button
            showLastButton // Show "Last" button
            sx={{ mt: 2, display: "flex", justifyContent: "center" }}
          />
        </Paper>
      )}
    </>
  );
};

export default DataTable;
