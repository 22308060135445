import React from 'react'
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import whiteTickIcon from "../../../assets/images/whitetck.svg";
import classes from "./customCheckbox.module.scss"

const CustomCheckbox  = ({ field, ...props }) => {
  return (
    <Checkbox
      {...field}
      {...props}
      icon={
        <RadioButtonUncheckedIcon
        className={classes.radioBtnIcon}
        />
      }
      checkedIcon={
        <span
        className={classes.checkBoxMain}
        />
      }
    />
  )
}

export default CustomCheckbox 