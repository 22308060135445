import React from "react";
import classes from "./loader.module.scss";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const Loader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItem: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        color="inherit"
        sx={{
          width: "29px !important",
          height: "29px !important",
        }}
      />
    </Box>
  );
};

export default Loader;
