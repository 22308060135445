import React from "react";
import classes from "./settings.module.scss";
import { Outlet } from "react-router-dom";
import SettingSiderbar from "./settingSidebar";

const Settings = () => {
  return (
    <div className={classes.settingLayout}>
      <div className={classes.settingSidebar}>
        <SettingSiderbar />
      </div>
      <div className={classes.wrapperContainer}>
        <Outlet />
      </div>
    </div>
  );
};

export default Settings;
