import React from "react";
import classes from "./users.module.scss";

const Products = () => {
  return (
    <>
      <div className={classes.mainUser}>
        <h1>Products</h1>
      </div>
    </>
  );
};

export default Products;
