import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./lang/en.json";
import translationRO from "./lang/ro.json";

const resources = {
  en: {
    translation: translationEN,
  },
  ro: {
    translation: translationRO,
  },
};

i18n
  .use(LanguageDetector) // Detects the user language
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en", // Fallback language
    interpolation: {
      escapeValue: false, // React already escapes content
    },
  });

export default i18n;
