import axios from "../utilities/index";

export const getAllUsers = (page, pageSize, headers) => {
  return axios.get(`/AllUsers?page=${page}&per_page=${pageSize}`, headers);
};

export const deleteUser = (id, headers) => {
  return axios.delete(`/DeleteUser/${id}`, headers);
};
export const getCardDetail = (id) => {
  return axios.get(`/cards/card/${id}`);
};
