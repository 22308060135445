import React from "react";
import classes from "./users.module.scss";

const Notifications = () => {
  return (
    <>
      <div className={classes.mainUser}>
        <h1>Notifications</h1>
      </div>
    </>
  );
};

export default Notifications;
