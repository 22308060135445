import React from "react";
import classes from "./button.module.scss";

const Button = ({
  onClick,
  children,
  type,
  customClass,
  customWidth,
  fontsize,
  fullWidth,
  grey,
  black,
  custborderRadius,
  shadowOrange,
  shadowGrey,
  shadowBlack,
  outline,
  outlineGrey,
  outlineBlack,
  lightOrange,
  colorOrange,
  buttonCenter,

  ...rest
}) => {
  return buttonCenter ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <button
        onClick={onClick}
        type={type ? type : "button"}
        style={{
          ...(customWidth && { maxWidth: customWidth, width: "100%" }),
          ...(fontsize && { fontSize: fontsize }),
        }}
        className={`
        ${classes.button}
        ${customClass ? customClass : ""}
        ${customWidth ? classes.setWidth : ""}
        ${fullWidth ? classes.fullWidth : ""}
        ${grey ? classes.grey : ""}
        ${black ? classes.black : ""}
        ${lightOrange ? classes.lightOrange : ""}
        ${shadowOrange ? classes.shadowOrange : ""}
        ${shadowGrey ? classes.shadowGrey : ""}
        ${shadowBlack ? classes.shadowBlack : ""}
        ${outline ? classes.outline : ""}
        ${outlineGrey ? classes.outlineGrey : ""}
        ${outlineBlack ? classes.outlineBlack : ""}
        ${colorOrange ? classes.colorOrange : ""}
        ${custborderRadius ? classes.custborderRadius : ""}
        
        `}
        {...rest}
      >
        {children}
      </button>
    </div>
  ) : (
    <button
      onClick={onClick}
      type={type ? type : "button"}
      style={{
        ...(customWidth && { maxWidth: customWidth, width: "100%" }),
        ...(fontsize && { fontSize: fontsize }),
      }}
      className={`
        ${classes.button}
        ${customClass ? customClass : ""}
        ${customWidth ? classes.setWidth : ""}
        ${fullWidth ? classes.fullWidth : ""}
        ${grey ? classes.grey : ""}
        ${black ? classes.black : ""}
        ${lightOrange ? classes.lightOrange : ""}
        ${shadowOrange ? classes.shadowOrange : ""}
        ${shadowGrey ? classes.shadowGrey : ""}
        ${shadowBlack ? classes.shadowBlack : ""}
        ${outline ? classes.outline : ""}
        ${outlineGrey ? classes.outlineGrey : ""}
        ${outlineBlack ? classes.outlineBlack : ""}
        ${colorOrange ? classes.colorOrange : ""}
        ${custborderRadius ? classes.custborderRadius : ""}
        `}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
