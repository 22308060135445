import React, { useState, useEffect, Fragment } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogout } from "../../api/auth";
import { ToastContainer, toast } from "react-toastify";
import "./sidebarBuiltin.scss";
import classes from "./sidebar.module.scss";
import { LOGOUT } from "../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import logoF from "../../assets/images/logoF.png";
import closeCaret from "../../assets/images/closeCaret.png";
import openCaret from "../../assets/images/openCaret.png";
import logouts from "../../assets/images/logouts.png";
import * as Icons from "@mui/icons-material"; // Import all Material-UI icons

const drawerWidth = 240;

const Sidebar = ({ setCloseNavbar2, setIsNavbar, handleArrow, isNavbar }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  // Get the user's permissions from localStorage (or other auth methods)
  const userDataLocal = JSON.parse(localStorage.getItem("userData")) || [];
  // get menu data
  const [menuDataAPI, setMenuDataAPI] = useState(userDataLocal?.data?.menu);

  // add icon
  const getIconComponent = (iconName) => {
    // Remove the "Icon" suffix from the icon name, if present
    const formattedIconName = iconName.replace(/Icon$/, ""); // Removes "Icon" from "EmailOutlinedIcon"

    const IconComponent = Icons[formattedIconName]; // Get the icon component from the formatted icon name

    // Fallback if the icon is not found
    if (!IconComponent) {
      return <Icons.HelpOutline />; // Default to a placeholder icon if iconName is not found
    }

    return <IconComponent />;
  };
  const userGetData = JSON.parse(localStorage.getItem("userData"));
  const userID = userGetData?.data?.profile?.id;
  let pathSegments = location.pathname.split("/").filter(Boolean);
  let itemLink =
    pathSegments.length > 0 ? `/${pathSegments[0]}` : "/overview/summary";
  let dropLink = pathSegments.length > 0 ? `${pathSegments[0]}` : "overview";
  let dropItemLink =
    pathSegments.length > 0
      ? `/${pathSegments[0]}/${pathSegments[1]}`
      : "/overview/summary";

  const [openDropdown, setOpenDropdown] = useState(null);
  const [activeOption, setActiveOption] = useState(itemLink);
  const [activeOptionDrop, setActiveOptionDrop] = useState(dropLink);
  const [activeOptionDropItem, setActiveOptionDropItem] =
    useState(dropItemLink);

  useEffect(() => {
    setActiveOption(
      pathSegments.length > 0 ? `/${pathSegments[0]}` : "/overview/summary"
    );
    setActiveOptionDrop(
      pathSegments.length > 0 ? `${pathSegments[0]}` : "overview"
    );
    setActiveOptionDropItem(
      pathSegments.length > 0
        ? `/${pathSegments[0]}/${pathSegments[1]}`
        : "/overview/summary"
    );
  }, [location]);
  useEffect(() => {
    if (
      menuDataAPI.length > 0 &&
      activeOptionDropItem === "/overview/summary"
    ) {
      // Open the first dropdown if it has items
      if (menuDataAPI[0]?.dropdown) {
        setOpenDropdown(0);
        setActiveOptionDrop(menuDataAPI[0]?.title.toLowerCase());
      }
    }
  }, [menuDataAPI]);
  const handleClick = (dropdownId) => {
    setOpenDropdown((prevOpenDropdown) =>
      prevOpenDropdown === dropdownId ? null : dropdownId
    );
  };

  const handleItemClick = () => {
    setOpenDropdown(null);
    setActiveOptionDrop(null);
    setActiveOptionDropItem(null);
    setCloseNavbar2(true);
    // setIsNavbar(false);
  };

  const handleOptionClick = (option) => {
    setActiveOption(option);
  };

  const handleOptionDropClick = (option) => {
    const ada = option.toLowerCase();
    setActiveOptionDrop(ada);
    setIsNavbar(false);
  };

  const handleOptionDropClickItem = (option) => {
    setActiveOptionDropItem(option);
    setActiveOption(null);
    setCloseNavbar2(true);
    setIsNavbar(false);
  };

  const handleLogout = async () => {
    try {
      const tokenAD = JSON.parse(localStorage.getItem("authToken"));
      const lang = localStorage.getItem("lang");
      // Call the logout function with the token in the Authorization header
      localStorage.removeItem("userData");
      localStorage.removeItem("authToken");
      localStorage.removeItem("userRole");
      setTimeout(() => {
        navigate("/sign-in");
      }, 1500);
      const res = await userLogout(userID, {
        headers: {
          Authorization: `Bearer ${tokenAD}`,
          lang: lang,
        },
      });

      if (res?.status == 200) {
        toast.success(res?.data?.message);
        dispatch({
          type: LOGOUT,
        });
      }
    } catch (error) {
      const showErr =
        error?.response?.data?.message == "Account Blocked"
          ? error?.response?.data?.errors[0]
          : error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message;
      // toast.error(showErr);
    }
  };
  const checkPermission = (menuItemPermission) => {
    return userDataLocal.data.permissions.includes(menuItemPermission);
  };
  const routeGo =
    (menuDataAPI[0].collapsable && menuDataAPI[0]?.dropdown) ||
    (!menuDataAPI[0].collapsable && menuDataAPI[0]?.dropdown)
      ? menuDataAPI[0]?.items[0]?.link
      : menuDataAPI[0]?.link;
  return (
    <>
      <ToastContainer />
      <div
        className={`${classes.arrow} ${isNavbar && classes.closeArrowStyle}`}
        onClick={handleArrow}
      >
        <img src={isNavbar ? openCaret : closeCaret} alt="arrow" />
      </div>

      <div className={`${classes.sideBarContainer}`}>
        <div className={classes.logo}>
          <Link to={`/${routeGo}`}>
            {isNavbar ? (
              <img src={logo} alt="logo" className={classes.onelogo} />
            ) : (
              <img src={logoF} alt="logo" className={classes.fulllogo} />
            )}
          </Link>
        </div>

        <div className={`${classes.navLinkSection} sidebar`}>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
              },
            }}
            variant="permanent"
          >
            <List>
              {menuDataAPI.map((menuItem, index) => {
                // Check if dropdown should be shown (if any sub-item is accessible)
                const hasPermission = checkPermission(menuItem?.permission);
                const hasAccessibleSubItem = menuItem?.items?.some((item) =>
                  checkPermission(item?.permission)
                );

                return (
                  (hasPermission || hasAccessibleSubItem) && (
                    <Fragment key={index}>
                      {menuItem?.dropdown && menuItem?.collapsable ? (
                        <>
                          <ListItem
                            button
                            onClick={() => {
                              handleOptionDropClick(menuItem?.title);
                              handleClick(index);
                            }}
                            selected={
                              activeOptionDrop === menuItem?.title.toLowerCase()
                            }
                            className={
                              activeOptionDrop === menuItem?.title.toLowerCase()
                                ? "Mui-selected"
                                : ""
                            }
                          >
                            {getIconComponent(menuItem?.icon)}

                            {/* <img
                              src={menuItem?.icon}
                              alt={menuItem?.title}
                              className="sidebarIcon"
                            /> */}
                            <ListItemText primary={menuItem?.title} />
                            {openDropdown === index ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItem>
                          <Collapse
                            in={openDropdown === index}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              {menuItem?.items.map(
                                (item, subIndex) =>
                                  checkPermission(item?.permission) && (
                                    <ListItem
                                      key={subIndex}
                                      button
                                      sx={{ pl: 4 }}
                                      selected={
                                        activeOptionDropItem ===
                                        `/${item?.link}`
                                      }
                                      className={
                                        activeOptionDropItem ===
                                        `/${item?.link}`
                                          ? "Mui-selected"
                                          : ""
                                      }
                                      component={Link}
                                      to={`/${item?.link}`}
                                      onClick={() =>
                                        handleOptionDropClickItem(
                                          `/${item?.link}`
                                        )
                                      }
                                    >
                                      {getIconComponent(item?.icon)}
                                      {/* <img
                                        src={item?.icon}
                                        alt={item?.title}
                                        className="sidebarIcon"
                                      /> */}
                                      <ListItemText primary={item?.title} />
                                    </ListItem>
                                  )
                              )}
                            </List>
                          </Collapse>
                        </>
                      ) : menuItem?.dropdown && !menuItem?.collapsable ? (
                        <ListItem
                          button
                          selected={activeOption === `/${menuItem?.link}`}
                          className={
                            activeOption === `/settings`
                              ? "Mui-selected lastChildMenu"
                              : "lastChildMenu"
                          }
                          component={Link}
                          to={`/settings/users`}
                          onClick={() => {
                            handleOptionClick(`/settings`);
                            handleItemClick();
                          }}
                        >
                          {getIconComponent(menuItem?.icon)}

                          {/* <img
                            src={menuItem?.icon}
                            alt={menuItem?.title}
                            className="sidebarIcon"
                          /> */}
                          <ListItemText primary={menuItem?.title} />
                        </ListItem>
                      ) : (
                        <ListItem
                          button
                          selected={activeOption === `/${menuItem?.link}`}
                          className={
                            activeOption === `/${menuItem?.link}`
                              ? "Mui-selected"
                              : ""
                          }
                          component={Link}
                          to={`/${menuItem?.link}`}
                          onClick={() => {
                            handleOptionClick(`/${menuItem?.link}`);
                            handleItemClick();
                          }}
                        >
                          {getIconComponent(menuItem?.icon)}
                          {/* <img
                            src={menuItem?.icon}
                            alt={menuItem?.title}
                            className="sidebarIcon"
                          /> */}
                          <ListItemText primary={menuItem?.title} />
                        </ListItem>
                      )}
                    </Fragment>
                  )
                );
              })}
            </List>
          </Drawer>
        </div>
        <div className={`${classes.otherlink} logoutSec`}>
          <p>
            <Link to="/help">Help</Link>
          </p>
          <p>
            <Link to="/contact">Contact us</Link>
          </p>
          <h4 onClick={handleLogout}>
            <img src={logouts} alt="" /> <span>Log out</span>
          </h4>
        </div>
        {/* <div className={`${classes.mainLogout} ${isNavbar && "closeLogout"}`}>
          <div className={classes.chef_container}>
            <img src={chef} className={classes.chefimg} />
            <h6>Organize your menus through button bellow</h6>
            <Button
              customClass={classes.menuBtn}
              onClick={handleMenu}
              fontsize="1rem"
              customWidth="155px"
            >
              + Add Menus
            </Button>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Sidebar;
