import React from "react";
import classes from "./users.module.scss";

const Privacy = () => {
  return (
    <>
      <div className={classes.mainUser}>
        <h1>Privacy</h1>
      </div>
    </>
  );
};

export default Privacy;
