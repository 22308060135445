import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";
import classes from "./customInput.module.scss";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import CustomCheckbox from "../customCheckbox";
import { useTranslation } from "react-i18next";

const InputField = ({
  // for input
  label,
  type,
  name,
  value,
  onBlur,
  onChange,
  placeholder,
  touched,
  errors,
  customClass,
  // for select
  options,
  // for pass eye icon
  eyeIcon,
  setPasswordChange,
  passwordChange,

  // radio heading
  heading,
}) => {
  const { t, i18n } = useTranslation();
  const toggleShowPassword = () => {
    setPasswordChange(!passwordChange);
  };
  return (
    <>
      {type == "radio" ? (
        <div className={classes.radioStyle}>
          {heading && <label>{heading}</label>}
          <div
            role="group"
            aria-labelledby="my-radio-group"
            className={classes.radioLabel}
          >
            {options.map((option) => (
              <label key={option.value}>
                <Field
                  type={type}
                  name={name}
                  value={option.value}
                  className={classes.radioButton}
                />
                <span className={classes.radioButton__control}></span>
                {option.label}
              </label>
            ))}
          </div>
          {errors && (
            <div className={classes.errorMsg}>
              <ErrorMessage name={name} />
            </div>
          )}
        </div>
      ) : type == "checkbox" ? (
        <>
          <div
            className={`${classes.remCheckbox} ${customClass && customClass}`}
          >
            <Field
              type={type}
              name={name}
              checked={value}
              className={`${classes.checkboxField}`}
              component={CustomCheckbox}
            />
            {label && label}
          </div>
          {errors && (
            <div className={classes.errorMsg}>
              <ErrorMessage name={name} />
            </div>
          )}
        </>
      ) : (
        <div className={`${classes.inputMain} ${customClass && customClass}`}>
          {label && <label>{label}</label>}
          {eyeIcon ? (
            <div className={classes.eyeBox}>
              <Field
                type={type}
                name={name}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                className={`${classes.inputField} ${
                  errors && touched ? classes.errorMsgLine : null
                }`}
                placeholder={placeholder ? placeholder : ""}
              />
              <IconButton
                onClick={toggleShowPassword}
                className={classes.eyeIcon}
              >
                {passwordChange ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </div>
          ) : type == "select" ? (
            <div className={classes.inputFieldSelect}>
              <Field
                as={type}
                name={name}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                className={`${classes.inputField} ${
                  errors && touched ? classes.errorMsgLine : null
                }`}
                placeholder={placeholder}
              >
                <option value="">
                  {placeholder ? placeholder : "Select option"}
                </option>
                {options?.map((optionName) => (
                  <option value={optionName}>{optionName}</option>
                ))}
              </Field>
            </div>
          ) : (
            type && (
              <Field
                type={type}
                name={name}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                className={`${classes.inputField} 
                ${errors && touched ? classes.errorMsgLine : null}
                `}
                placeholder={placeholder}
              />
            )
          )}

          {errors && (
            <div className={classes.errorMsg}>
              <ErrorMessage name={name} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default InputField;
