import React, { useState } from "react";
import { Formik, Form } from "formik";
import classes from "./ResetPassword.module.scss";
import { userResetPassword } from "../../../api/auth";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import resetBanner from "../../../assets/images/loginBanner2.jpg";
import CustomInput from "../../../components/shared/customInput";
import Button from "../../../components/shared/customBtn";
import { useTranslation } from "react-i18next";
import CustomLanguage from "../../../components/shared/customLanguage";
import Loader from "../../../components/shared/loader";
import { useLocation, Navigate, useNavigate } from "react-router-dom";

const changePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("New Password is required")
    .min(8, "Password must be at least 8 characters long")
    .max(12, "Password cannot exceed 12 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*]/,
      "Password must contain at least one special character (e.g, !, @, #)"
    ),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm New Password is required"),
});
const ChangePassword = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConPass, setShowConPass] = useState(false);

  // Parse query parameters from the location.search
  const queryParams = new URLSearchParams(location.search);

  // Extract token and email
  const token = queryParams.get("token");
  const email = queryParams.get("email");

  // Redirect to login page if token or email is missing
  if (!token || !email) {
    return <Navigate to="/login" />;
  } else {
    return (
      <div className={classes.mainChangePassword}>
        <ToastContainer />
        <div className={classes.defaultForm}>
          <Formik
            initialValues={{ password: "", password_confirmation: "" }}
            validationSchema={changePasswordSchema}
            onSubmit={async (values) => {
              setLoading(true);
              const lang = localStorage.getItem("lang");
              const valueData = {
                password: values.password,
                password_confirmation: values.password_confirmation,
                token: token,
                email: email,
              };
              try {
                const res = await userResetPassword(valueData, {
                  headers: {
                    lang: lang,
                  },
                });
                if (res?.status == 200) {
                  toast.success(res?.data?.message);
                  setTimeout(() => {
                    setLoading(false);
                    navigate("/sign-in");
                  }, 2000);
                }
              } catch (error) {
                setLoading(false);
                const showErr =
                  error?.response?.data?.message == "Account Blocked"
                    ? error?.response?.data?.errors[0]
                    : error?.response?.data?.message
                    ? error?.response?.data?.message
                    : error?.message;
                toast.error(showErr);
              }
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
              isValid,
              dirty,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className={classes.formHeader}>
                  {/* <img src={logo} alt="logo" /> */}
                  <h1>{t("resetPage.heading")}</h1>
                  <p>{t("resetPage.description")}</p>
                </div>
                <CustomLanguage />
                <CustomInput
                  label={t("resetPage.passLabel")}
                  type={showNewPass ? "text" : "password"}
                  name="password"
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={t("resetPage.passLabel")}
                  touched={touched.password}
                  errors={errors.password}
                  eyeIcon
                  setPasswordChange={setShowNewPass}
                  passwordChange={showNewPass}
                />
                <CustomInput
                  label={t("resetPage.newPassLabel")}
                  type={showConPass ? "text" : "password"}
                  name="password_confirmation"
                  value={values.password_confirmation}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={t("resetPage.newPassLabel")}
                  touched={touched.password_confirmation}
                  errors={errors.password_confirmation}
                  eyeIcon
                  setPasswordChange={setShowConPass}
                  passwordChange={showConPass}
                />

                <Button type="submit" black disabled={!(isValid && dirty)}>
                  {loading ? <Loader /> : <>{t("resetPage.resetBtn")}</>}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        <div className={classes.rightBanner}>
          <img src={resetBanner} alt="reset banner" height="100%" />
        </div>
      </div>
    );
  }
};

export default ChangePassword;
