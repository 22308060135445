import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import classes from "./dashboard.module.scss";
import "./dashboardBuiltin.scss";
// import forkwhite from "../../assets/images/forkwhite.png";
// import revenuewhite from "../../assets/images/revenuewhite.png";
// import orderwhite from "../../assets/images/orderwhite.png";
// import OrderSummary from "./OrderSummary";
// import Revenue from "./Revenue";
// import TableFilter from "../../components/shared/tableFilter";
// import Button from "../../components/shared/customBtn";
// import { Container } from "@mui/material";
// import Foodimage from "../../assets/images/foodimage.png"
// import dropImg from "../../assets/images/drop.svg";
// import acceptImg from "../../assets/images/accept.svg";
// import rejectImg from "../../assets/images/reject.svg";
// import detailImg from "../../assets/images/detail.svg";
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import { Link } from "react-router-dom";
// import Checkbox from "@mui/material/Checkbox";
// import ListItemText from "@mui/material/ListItemText";
// import fork from "../../assets/images/fork.svg";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import InputAdornment from "@mui/material/InputAdornment";
// import OutlinedInput from "@mui/material/OutlinedInput";

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
// ];
const Home = () => {
  const navigate = useNavigate();
  // const names = ["Foodie Gulberg", "Foodie Johar Town", "Foodie Askari 11"];
  // const recentOrders = [
  //   {
  //     id: '#01373812',
  //     name: 'Hassan Ali',
  //     description: 'Top 10 Must-Try Dishes in Lahore',
  //     address: '65L Main Gulberg, Lahore',
  //     image: Foodimage,
  //     topDishes: ['Dish 1', 'Dish 2', 'Dish 3'],
  //     price: '$7.4',
  //     quantity: 3,
  //     statustype: 'Pending'
  //   },
  //   // Add more orders as needed
  //   {
  //     id: '#01373812',
  //     name: 'Hassan Ali',
  //     description: 'Top 10 Must-Try Dishes in Lahore',
  //     address: '65L Main Gulberg, Lahore',
  //     image: Foodimage,
  //     topDishes: ['Dish 1', 'Dish 2', 'Dish 3'],
  //     price: '$7.4',
  //     quantity: 3,
  //     statustype: 'Delivered'
  //   }
  // ];
  // const TrendingMenu = [
  //   {

  //     item: "Chicken curry special with cucumber",
  //     itemImage: Foodimage,
  //     price: '$7.4',
  //     order: 'Orders 89x'
  //   },
  //   {

  //     item: "Chicken curry special with cucumber",
  //     itemImage: Foodimage,
  //     price: '$7.4',
  //     order: 'Orders 89x'
  //   }
  // ]
  // const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

  // const handleOpenDropdown = (index) => {
  //   setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  // };

  // const handleCloseDropdown = () => {
  //   setOpenDropdownIndex(null);
  // };

  // // drop func
  // const [restaurantName, setRestaurantName] = useState([]);

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setRestaurantName(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };
  // const [showDrop, setShowDrop] = useState(false);
  // const dropdownRef = useRef(null);

  // const handleDrop = () => {
  //   setShowDrop((prev) => !prev);
  // };
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //       handleDrop();
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  // }, []);
  return (
    <div>
      <div className={classes.mainDashboard}>
        <div className={classes.heading}>
          <div className={classes.headingText}>
            <h1>Dashboard</h1>
            <p>Hi, Matthew Parker. Welcome back to 24Credit!</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
