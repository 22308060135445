import axios from "../utilities/index";

export const userLogin = (data, headers) => {
  return axios.post("/Auth", data, headers);
};
export const userForgotPass = (data, headers) => {
  return axios.post("/ForgotPassword", data, headers);
};

export const userResetPassword = (data, headers) => {
  return axios.post("/ResetPassword", data, headers);
};
export const userLogout = (id, headers) => {
  return axios.get(`/Logout/${id}`, headers);
};

export const userVerifyAccount = (data, headers) => {
  return axios.post("/VerifyUserAccount", data, headers);
};
